var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "point-schemes-guide" }, [
        _c("dl", [
          _c("dt", [_vm._v("포인트란?")]),
          _c("dd", [
            _vm._v(
              "포인트는 선생님의 닥터인포 활동에 따라 적립되는 포인트로, 포인트를 모으시면 포인트 몰에서 백화점 상품권, 주유권, 외식 상품권 등 다양한 상품으로 교환하실 수 있습니다."
            ),
          ]),
          _c("dd", [
            _vm._v(
              "포인트를 사용하실 때 최초 1회 휴대폰 인증이 필요하며, 인증을 완료한 다음부터는 간편 비밀번호 입력만으로 간단하게 포인트를 사용하실 수 있습니다."
            ),
          ]),
        ]),
        _c("div", { staticClass: "point-schemes-guide-item" }, [
          _c("p", { staticClass: "title" }, [
            _c("span", [_vm._v("01.")]),
            _vm._v("포인트 적립 방법 "),
          ]),
          _c("div", { staticClass: "gray-box" }, [
            _c("ul", [
              _c("li", [
                _vm._v(
                  "이 달의 미션 : 매월 변동되며, 해당 미션을 수행하면 포인트가 자동으로 적립."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "상시 적립 : 설문 조사 및 이벤트 참여에 따라 자동으로 적립."
                ),
              ]),
            ]),
          ]),
          _c("p", { staticClass: "tip" }, [
            _vm._v(
              " 포인트는 메디컬 트렌드 댓글 참여 횟수, 제약 웹 세미나 시청 완료 횟수와 설문 조사 참여, 이벤트 참여 등에 따라 적립됩니다."
            ),
            _c("br"),
            _vm._v(
              " 이 달의 미션은 매월 변동되며, 포인트가 지급되는 자세한 기준은 기준은 포인트 몰 내 포인트 적립 내역 탭, 상단의 이번 달 참여 현황, 공지사항/이벤트 탭에서 확인하실 수 있습니다."
            ),
            _c("br"),
            _c("span", { staticClass: "txt-blue" }, [
              _vm._v(
                "※ 이용약관을 위반하여 관리자에 의해 댓글이 삭제된 경우, 포인트가 적립되지 않습니다."
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "point-schemes-guide-item" }, [
          _c("p", { staticClass: "title" }, [
            _c("span", [_vm._v("02.")]),
            _vm._v("포인트 유효 기간 "),
          ]),
          _c("p", { staticClass: "tip" }, [
            _vm._v(
              " 포인트의 유효기간은 최초 적립 시점에서 12개월이 지난 시점의 해당 월 말일이며, 해당 기간 안에 사용하지 못한 잔여 포인트는 매월 말일 자동 소멸됩니다."
            ),
            _c("br"),
            _vm._v(
              " 포인트가 자동 소멸되는 일이 없도록 유효기간과 소멸일을 꼭 확인하세요. "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }